<template>
  <div class="">
    <el-form
      class="add-form signature-form"
      @submit.prevent="checkSignatureInformation"
      @keydown.enter.prevent="() => {}"
      :model="signature"
      label-position="top"
      ref="signatureForm"
    >
      <!-- Organization -->
      <div class="form-wrapper full">
        <el-form-item
          prop="organization.id"
          :rules="[
            {
              required: true,
              message: $t('COMMON.ORGANIZATION_REQUIRED'),
            },
          ]"
        >
          <base-input
            :label="`${$t('COMMON.ORGANIZATION')} (*)`"
            :placeholder="$t('COMMON.ORGANIZATION')"
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
              !hideOrganization
            "
          >
            <organization-selector
              :organization="signature.organization.id"
              :filterable="true"
              :showAll="false"
              @organizationChanged="
                (organizationId) => {
                  signature.organization.id = organizationId;
                  onFormChanged();
                }
              "
              :disabled="!!signature.id"
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />
        </el-form-item>
      </div>
      <div class="form-wrapper full my-2">
        <el-checkbox :disabled="disableTemplateSelection" @input="openOrCloseModelSelector" v-model="fromModel">{{
          $t("SIGNATURES.FROM_MODEL")
        }}</el-checkbox>
      </div>
      <div v-if="fromModel" class="form-group-wrapper form-model w-100">
        <!-- Filtrer les contrats -->
        <!-- <el-form-item
          class="form-group-wrapper-item"
          :label="`${$t('CONTRACTS.FILTER_CONTRACTS')}`"
          prop="contract_type"
        >
          <el-select
            :name="`${$t('CONTRACTS.CONTRACT_TYPE')}`"
            v-model="contractType"
          >
            <el-option
              v-for="(contract, val) in CONTRACT_TYPES"
              :key="val"
              :value="val"
              :label="`${val ? $t('CONTRACTS.' + val) : ''}`"
            />
          </el-select>
        </el-form-item> -->

        <!-- Sélectionner le contrat -->
        <el-form-item
          class="form-group-wrapper-item w-100"
          :label="`${$t('SIGNATURES.SELECT_MODEL')}`"
          prop="contract.id"
          :rules="[
            {
              required: true,
              message: $t('SIGNATURES.MODEL_REQUIRED'),
            },
          ]"
        >
          <contract-selector
            :contract="signature.contract?.id?.toString()"
            :filterable="true"
            :showAll="false"
            :allowCreate="false"
            :selectedType="contractType"
            :disabled="disableTemplateSelection"
            @contractChanged="contractSelected"
          />
          <validation-error :errors="apiValidationErrors.contract" />
        </el-form-item>
      </div>

      <div class="form-group-wrapper">
        <!-- Title - Name - Description - Email Message -->

        <!-- Esign name -->
        <div class="form-group-wrapper-item full">
          <el-form-item
            prop="name"
            :rules="[
              {
                required: true,
                message: $t('COMMON.NAME_REQUIRED'),
              },
            ]"
          >
            <base-input
              :label="`${$t('COMMON.NAME')} ${!signature.name ? '(*)' : ''}`"
            >
              <el-input
                v-model="signature.name"
                @change="
                  () => {
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.name" />
          </el-form-item>
        </div>

        <!-- Esign Description -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('COMMON.EXCERPT')}`"
            :placeholder="`${$t('COMMON.EXCERPT')}`"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
            <textarea
              v-model="signature.description"
              :placeholder="`${$t('COMMON.EXCERPT')}`"
              class="form-control"
              rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.description" />
        </div>

        <!-- Esign Email Message -->
        <div class="form-group-wrapper-item">
          <base-input
            :label="`${$t('SIGNATURES.EMAIL_MESSAGE')}`"
            :placeholder="`${$t('SIGNATURES.EMAIL_MESSAGE')}`"
            @change="
              () => {
                onFormChanged();
              }
            "
          >
            <textarea
              v-model="signature.email_message"
              :placeholder="`${$t('SIGNATURES.EMAIL_MESSAGE')}`"
              class="form-control"
              rows="3"
            ></textarea>
          </base-input>
          <validation-error :errors="apiValidationErrors.email_message" />
        </div>
      </div>
      <div class="form-group-wrapper">
        <div v-if="signature.contract?.id" class="signature-data el-form-item form-group-wrapper-item w-100">
          <!-- CONTRACT_FILES -->
          <div class="documents w-100 mt-3 mb-3" v-if="!usingOneSpanSignatureModel">
            <h3 class="text-danger">{{ $t("CONTRACTS.FILES") }}</h3>
            <el-divider />
            <file-list-component :contract="signature.contract" />
          </div>

          <!-- SIGNATAIRES -->

          <!-- Section Signataires -->
          <!-- :rules="[
                { required: true, message: $t('SIGNATURES.SIGNERS_REQUIERED') },
              ]" -->
          <div class="signers w-100 mb-3">
            <el-form-item
              :prop="'signers'"
              
            >
              <div class="row">
                <div class="col-md-4 text-start">
                  <h3 class="text-danger">
                    {{ $t("SIGNATURES.SIGNERS") }}
                  </h3>
                </div>
                <div class="col-md-8 d-flex justify-content-end">
                  <!-- Bouton pour ajouter un signataire -->
                  <el-button
                    v-if="!usingOneSpanSignatureModel"
                    class="my-3"
                    type="secondary"
                    icon="el-icon-plus"
                    @click="addSigner"
                    style="margin-top: 10px"
                  >
                    {{ $t("SIGNATURES.ADD_SIGNER") }}
                  </el-button>
                </div>
                <el-divider />
              </div>
              <validation-error :errors="apiValidationErrors.signers" />
            </el-form-item>

            <div
              class="add-signer mb-3"
              v-for="(signer, index) in signature.signers"
              :key="index"
            >
              <div class="delete-signer d-flex justify-content-end">
                <span
                  v-if="canRemoveSigner"
                  @click="removeSigner(index)"
                  class="text-right text-black cursor-pointer font-bold"
                >
                  <i class="fa fa-trash"></i>
                </span>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('COMMON.ROLE')"
                    :prop="'signers.' + index + '.role'"
                  >
                    <el-input
                      :disabled="usingOneSpanSignatureModel || isEditing"
                      v-model="signer.role"
                      :placeholder="$t('COMMON.ROLE')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('COMMON.EMAIL')"
                    :prop="'signers.' + index + '.email'"
                    :rules="[
                      {
                        required: true,
                        message: $t('COMMON.EMAIL_REQUIRED'),
                      },
                      {
                        type: 'email',
                        message: $t('COMMON.EMAIL_INCORRECT'),
                      },
                    ]"
                  >
                    <el-input
                      type="email"
                      v-model="signer.email"
                      :placeholder="$t('COMMON.EMAIL')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('COMMON.FIRSTNAME')"
                    :prop="'signers.' + index + '.firstname'"
                    :rules="[
                      {
                        required: true,
                        message: $t('COMMON.FIRSTNAME_REQUIRED'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="signer.firstname"
                      :placeholder="$t('COMMON.FIRSTNAME')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <el-form-item
                    :label="$t('COMMON.LASTNAME')"
                    :prop="'signers.' + index + '.lastname'"
                    :rules="[
                      {
                        required: true,
                        message: $t('COMMON.LASTNAME_REQUIRED'),
                      },
                    ]"
                  >
                    <el-input
                      v-model="signer.lastname"
                      :placeholder="$t('COMMON.LASTNAME')"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-12">
                  <el-form-item
                    :prop="'signers.' + index + '.address'"
                    :label="$t('COMMON.ADDRESS')"
                  >
                    <addresses-selector
                      v-model="signer.address"
                      :unique-id="'signer-address-input-' + index"
                      :ref="'signer_address_' + index"
                      :key="'signer_address_' + index"
                      :address="signer.address"
                      @addressInputChangeChanged="
                        (values) => {
                          signer.address = values.formatted_address ?? '';
                        }
                      "
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>

            <div v-if="usingOneSpanSignatureModel" class="form-wrapper full my-2">
              <el-checkbox v-model="sendNow">{{
                $t("SIGNATURES.SEND_FOR_SIGNATURE")
              }}</el-checkbox>
            </div>

          <!-- EMAIL_MESSAGE -->
          <!-- <el-form-item
            class="form-group-wrapper-item w-100"
            :label="`${$t('SIGNATURES.EMAIL_MESSAGE')} (*)`"
            prop="email_message"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              :placeholder="$t('SIGNATURES.EMAIL_MESSAGE')"
              v-model="signature.email_message"
            >
            </el-input>
            <validation-error :errors="apiValidationErrors.email_message" />
          </el-form-item> -->
        </div>

        <div class="submit-wrapper">
          <base-button
            type="button"
            class="btn btn-sm elite-submit"
            native-type="submit"
            @click.prevent="checkSignatureInformation"
            :disabled="loading || processing"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading || processing" />
            {{
              signature.id
                ? $t("SIGNATURES.EDIT_SIGNATURE")
                : $t("SIGNATURES.ADD_SIGNATURE")
            }}
          </base-button>
        </div>
      </div>
    </el-form>

    <modal
      :show="showSignatureCheckModal"
      modal-classes="modal-secondary"
      size="lg"
    >
      <div class="" slot="header">
        <h2 id="">
          {{ $t("COMMON.CONFIRMATION") }}
        </h2>
      </div>
      <div class="">
        <div class="row">
          <p class="text-lg text-center">
            {{
              $t("SIGNATURES.SAME_SIGNATURES", {
                name: signature.name,
              })
            }}
          </p>
        </div>
        <div class="page-wrapper-table-body">
          <div class="page-wrapper-table-body-inner">
            <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="signatures"
            >
              <div class="table-loading" slot="empty" v-if="loading">
                <img src="/img/loading.gif" alt="" />
              </div>
              <el-table-column
                :label="$t('COMMON.NAME')"
                min-width="250"
                prop="name"
              >
                <template v-slot="{ row }">
                  <span>
                    {{ `${row.name}` }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('COMMON.EXCERPT')" min-width="300">
                <template v-slot="{ row }">
                  {{ row.excerpt ?? "" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="continueFormSubmitProcess"
          :disabled="loading || processing"
        >
          <span v-if="loading || processing" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("COMMON.CONTINUE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="toggleSignatureCheckModalState"
          :disabled="loading || processing"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Option,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Button,
  Checkbox,
  Divider,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { SIGNATURE_TYPES } from "@/constants/signatures";
import ContractSelector from "../../ContractManagement/components/ContractSelector.vue";
import FileListComponent from "../../ContractManagement/components/files/partials/FileListComponent.vue";
import { CONTRACT_TYPES } from "@/constants/contracts";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";
export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    ContractSelector,
    FileListComponent,
    OrganizationSelector,
    AddressesSelector
  },

  mixins: [formMixin],

  props: [
    "hideOrganization",
    "signatureData",
    "formErrors",
    "loading",
    "hideSubmit",
  ],
  data() {
    let signatureData = { ...this.signatureData };
    signatureData = this.$fillUserOrganizationData(signatureData);
    return {
      usingOneSpanSignatureModel:false,
      sendNow:false,
      fromModel: false,
      contractType: null,
      signature: signatureData,
      signatures: [],
      total: 0,
      showModal: false,
      showSignatureCheckModal: false,
      processing: false,
      SIGNATURE_TYPES: SIGNATURE_TYPES,
      CONTRACT_TYPES: CONTRACT_TYPES,
      disableTemplateSelection:false,
    };
  },

  created() {
    if(this.signature?.contract?.id){
      this.fromModel = true;
      this.usingOneSpanSignatureModel = true;
      this.disableTemplateSelection = true;
    }else{
      this.fromModel = false;
      this.usingOneSpanSignatureModel = false;
      if(this.signature?.token){
        this.disableTemplateSelection = true;
      }else{
        this.disableTemplateSelection = false;
      }
    }
  },

  mounted() {},

  computed: {
    isEditing(){
      return !!this.signature.id;
    },
    canRemoveSigner(){
      if(this.usingOneSpanSignatureModel){
        return false;
      }else if(this.signature.id){
        return false;
      }
      return true;
    }
  },

  methods: {
    contractSelected(contract) {
      this.usingOneSpanSignatureModel = contract.token!=null;
      this.signature.contract = {
        type: "contracts",
        id: contract.id,
      };
      this.signature.name = contract.name;
      this.signature.description = contract.excerpt;
      this.signature.signers = [];
      if(contract.roles.length){
        this.addSignersFromRoles(contract.roles);
      }
    },
    openOrCloseModelSelector(){
        this.usingOneSpanSignatureModel = false;
        this.signature.signers = [];
        this.signature.name = '';
        this.signature.description = '';
        this.signature.contract.id = null;
    },
    async handleSubmit() {
      const checkUniqueSignature = await this.checkSignatureUniqueInformation();
      if (checkUniqueSignature) {
        await this.sendInformation();
      } else {
        this.showSignatureCheckModal = true;
      }
    },

    async sendInformation() {
      if (this.signature.name) {
        this.signature.name = this.signature.name.toString();
      }
      let signatureData = cloneDeep(this.signature);
      signatureData = this.$fillUserOrganizationData(signatureData);
      if (signatureData.contract==null || signatureData.contract?.id == null) {
        delete signatureData.contract;
      }
      if(this.sendNow){
        signatureData.sendNow=1;
      }
      this.$emit("signatureSubmitted", signatureData);
    },

    cancelEdition() {
      this.$emit("cancelEdition");
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    async getList() {
      if (!this.signature?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: `${this.signature?.name}`,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
        };
        await this.$store.dispatch("signatures/list", params);
        this.signatures = this.$store.getters["signatures/list"];
        this.total = this.$store.getters["signatures/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async checkSignatureUniqueInformation() {
      await this.getList();
      switch (this.total) {
        case 0:
          return true;
        case 1:
          return !!this.signature?.id;
        default:
          return false;
      }
    },

    async continueFormSubmitProcess() {
      this.processing = true;
      await this.toggleSignatureCheckModalState();
      await this.sendInformation();
    },

    async toggleSignatureCheckModalState() {
      this.showSignatureCheckModal = !this.showSignatureCheckModal;
    },

    checkSignatureInformation() {
      this.$refs["signatureForm"].validate(async (valid) => {
        if (valid) {
          this.handleSubmit();
        }
      });
    },

    // Méthode pour ajouter un signataire
    addSignersFromRoles(roles) {
      roles.forEach((role) => {
        this.signature.signers.push({
          role: role,
          firstname: "",
          lastname: "",
          address: "",
          phone: "",
          email: "",
        });
      });
    },

    addSigner() {
      let roleKey = this.signature.signers.length +1;
      this.signature.signers.push({
        role: "Signataire"+roleKey,
        firstname: "",
        lastname: "",
        address: "",
        phone: "",
        email: "",
      });
    },
    // Méthode pour supprimer un signataire
    removeSigner(index) {
      this.signature.signers.splice(index, 1);
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    signatureData(signatureData) {
      if (signatureData) {
        this.signature = {
          ...this.signature,
          ...cloneDeep(signatureData),
        };
      }
    },
  },
};
</script>

<style>
.add-form .btn-cancel {
  font-size: 14px;
  min-height: 40px;
  border-radius: 8px;
  padding: 0 15px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.add-form .el-input__inner,
.add-form .el-textarea {
  background-color: transparent;
  border-radius: 8px;
  color: #000;
}
.form-group-wrapper-item .el-select {
  width: 100%;
}
.form-group-wrapper-item label {
  margin-bottom: 0.1rem !important;
}
.form-group-wrapper-item .el-form-item__label {
  padding: 0 0 1px !important;
}
</style>
