<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!signature">
      <span class="loader"></span>
    </span>
    <div v-if="signature" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="label"
        v-model="currentPane"
      >

        <tab-pane title="label" id="1">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <signature-view-global
            :signature="signature"
            :loading="loading"
            @onSignatureUpdated="get"
            @onEditSignature="editSignature"
            @onDeleteSignature="deleteSignature"
            @onSendSignature="sendSignature"
            @onGetSignedDocuments="getSignedDocuments"
          />
        </tab-pane>


        <tab-pane v-if="signature.edition_url" title="editor" id="2" :active="true">
          <span slot="title">
            <esign-icon :width="'20px'" class="custom-svg-icon"/>
            {{ $t("ESIGNS.EDITOR") }}
          </span>
          <package-editor
            :package-url="signature.edition_url"
          />
        </tab-pane>
        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <signature-view-logs :signature="signature" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import SignatureViewLogs from "../partials/SignatureViewLogs.vue";
import SignatureViewGlobal from "../partials/SignatureViewGlobal.vue";
import EsignIcon from '@/components/Icons/EsignIcon.vue';
import PackageEditor from "@/components/PackageEditor.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    SignatureViewLogs,
    SignatureViewGlobal,
    PackageEditor,
    EsignIcon
  },

  mixins: [],

  props: {
    signatureId: {
      type: [String, Number],
      required: true,
    },
    loading:{
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      currentPane:"label",
      signature: null,
    };
  },

  computed: {
    canBeEdit(){
      return this.signature.edition_url
    }
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("signatures/get", `${this.signatureId}_include_edition_url`);
        this.signature = this.$store.getters["signatures/signature"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editSignature(signature) {
      this.$emit("onEditSignature", signature);
    },
    deleteSignature(signature) {
      this.$emit("onDeleteSignature", signature);
    },
    sendSignature(signature) {
      this.$emit("onSendSignature", signature);
    },
    getSignedDocuments(signature) {
      this.$emit("onGetSignedDocuments", signature);
    },
  },
};
</script>
